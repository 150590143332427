<template>
  <div lol-home>
    <HomeHeader />
    <BattleAcademiaMatchCloseView v-if="ended" />
    <OfficialLeague v-else :officialLeagueData="officialLeagueData" :step="getStep" :ended="ended">
      <template #detail><BattleAcademiaDetail :step="getStep" /></template>
      <template #hero><BattleAcademiaHero /></template>
      <template #gameInfo><BattleAcademiaGameInfo /></template>
    </OfficialLeague>
    <CommunityLeague />
    <client-only><MyInfo /></client-only>
  </div>
</template>

<script>
import _ from 'lodash';
import { millisecond } from '@/utils/time';
import HomeHeader from '@/views/components/gameHome/HomeHeader.vue';
import OfficialLeague from '@/views/components/gameHome/OfficialLeague.vue';
import CommunityLeague from '@/views/components/gameHome/CommunityLeague.vue';
import MyInfo from '@/views/components/gameHome/MyInfo.vue';
import BattleAcademiaDetail from '@/views/components/gameHome/lol/BattleAcademiaDetail.vue';
import BattleAcademiaHero from '@/views/components/gameHome/lol/BattleAcademiaHero.vue';
import BattleAcademiaMatchCloseView from '@/views/components/gameHome/lol/BattleAcademiaMatchCloseView.vue';
import officialLeagueData from '@/data/official-league/officialLeagueData';
import BattleAcademiaGameInfo from '@/views/components/gameHome/lol/gameInfo/BattleAcademiaGameInfo.vue';
import meta from '@/data/meta/index';

export default {
  name: 'LolHome',
  data: () => ({
    ended: true,
  }),
  components: {
    HomeHeader,
    OfficialLeague,
    CommunityLeague,
    MyInfo,
    BattleAcademiaDetail,
    BattleAcademiaHero,
    BattleAcademiaMatchCloseView,
    BattleAcademiaGameInfo,
  },
  computed: {
    officialLeagueData() {
      return officialLeagueData[this.$route.params.id];
    },
    officialLeagueDataStepTime() {
      return _.get(this.officialLeagueData, 'stepTime', []);
    },
    getStep() {
      const today = millisecond(new Date());
      return Number(`${_.findIndex(this.officialLeagueDataStepTime, d => millisecond(d) > today)}`);
    },
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};
</script>
