<template>
  <p battle-academia-hero v-prx:intro="{ r: [4, 8], tx: [-200, 0], o: [0.1, 1] }"></p>
</template>

<script>
export default {
  name: 'BattleAcademiaHero',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[battle-academia-hero] { .wh(420, 362); .contain('@{bg}/bg_battleacademia_hero.png'); .abs; .lt(50%, 20); .ml(-270); z-index: 1;
  @media (@tl-up) {
    .wh(1000, 780); .ml(-600); .t(117);
  }
  @media (@ds-up) {
    .wh(700, 604); .ml(-850);
  }
}
</style>
