<template>
  <PSection match-close-view theme="white">
    <h2>누구나 참여할 수 있는 다양한 아마추어 대회 <br /><b>다음 아마추어 대회 소식이 궁금하다면?</b></h2>
    <button class="go-btn" @click="onClickDiscord">바로가기</button>
    <h1 class="holder-title">2021년 배틀 아카데미아 결과</h1>
    <PSection class="final-holder">
      <div class="inside">
        <label>리그가 종료 되었습니다.</label>
        <BattleAcademiaMainSymbol :progress="false" />
        <h3>최고 대학에게<br />주어지는 점령 깃발-</h3>
        <p class="date">기간 ㅣ 6월 21일(월) ~ 7월 11일(일)</p>
        <p class="msg">
          배틀 아카데미아가 성공적으로 막을 내렸습니다. 총 152개의 학교가 참여한 이번 공식 대회에서는 553개의 팀이 1위의 자리를 놓고 벌이는 숨막히는 혈전이 벌어졌습니다. 최종 우승은 고려대학교가 차지하였습니다.
        </p>
        <PItem class="count-box">
          <CountView title="출전 대학" :length="3" :value="groupCount" :prxs="collCardPrx" />
          <CountView title="출전 팀" :length="3" :value="teamCount" :prxs="collCardPrx" />
        </PItem>
        <div class="mov-holder">
          <SectionTitle title="HIGH LIGHT" title-ko="리그 하이라이트 영상" sm />
          <p class="mov">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/IhyixGy0lzw?autoplay=1&amp;loop=1&amp;mute=1&playlist=IhyixGy0lzw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" controls="0" allowfullscreen></iframe>
          </p>
          <!-- <p class="mov" /> -->
        </div>
        <div class="rank-holder">
          <SectionTitle title="RANKING" title-ko="리그 랭킹" sm />
          <ul class="flags">
            <li :class="`rank${index + 1}`" v-for="(info, index) in rankInfos" :key="`rank-${index}`">
              <ImageAsset :url="`/img/pages/icon/Rank_${index + 1}.svg`" bg-color="transparent" />
              <p>{{ info.data.name }}</p>
            </li>
          </ul>
        </div>
      </div>
      <img src="/img/pages/bg/bg_hero.png" />
    </PSection>
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import CountView from '@/views/components/common/CountView.vue';
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import BattleAcademiaMainSymbol from '@/views/components/gameHome/lol/BattleAcademiaMainSymbol.vue';
import ImageAsset from '@/views/components/common/ImageAsset.vue';

export default {
  name: 'BattleAcademiaMatchCloseView',
  mixins: [gtag],
  components: { BattleAcademiaMainSymbol, SectionTitle, CountView, ImageAsset },
  data() {
    return {
      collCardPrx: [
        { r: [0, 5], ty: [50, 0] },
        { r: [0.5, 5.5], ty: [-50, 0] },
        { r: [1, 6], ty: [50, 0] },
      ],
      groupCount: 0,
      teamCount: 0,
      rankInfos: [],
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    onClickDiscord() {
      this.trackEvent('Main_discord_btn', 'click', `${this.upRouteGameId}`);
      window.open('https://discord.gg/PkpEJxM98K');
    },
  },
  async mounted() {
    const res = await this.$services.home.getAcademiaSummary();
    this.groupCount = res.groupCount;
    this.teamCount = res.teamCount;
    this.rankInfos = res.groupRank?.ranks;
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[match-close-view] { .bgc(#191919); .c(white); overflow: hidden; .p(60, 20, 60); .tc; .rel; .bg('@{bg}/bg.png');
  > h2 {.fs(32, 44px); .light;
    & + p {.fs(24, 32px); .light; .mt(32); .pt(32); .rel; .c(rgba(255, 255, 255, .6));
      &:before {.cnt; .wh(52, 2); .bgc(white); .abs; .lt(50%, -4); .t-xc;}
    }
  }
  .go-btn { .flex; .flex-ai; .flex-jc; margin-right: auto; margin-left: auto; .c(#fff); .bold; .fs(20); .wh(260, 60); .mt(20); .br(40); background: linear-gradient(135deg, #2c81ff 0%, #0048b3 100%); .mb(42);
    &:before { .cnt; .wh(32, 24); .contain('@{icon}/discord.svg'); }
  }
  .holder-title { margin-left: auto; margin-right: auto; .font-g; .medium; .fs(20, 48); .bgc(#d13639); .br-t(12); .h(48); .vam; }
  .final-holder { .-a(#8c2b2d, 2px); .br-b(12); .tl; .rel; .crop; .bgc(#191919);
    > .inside { z-index: 2; .rel; .p(210, 20, 20); .-box;}
    > img {.abs; .lt(50%, 0); .t-xc; .ml(100); z-index: 1;}
    label {.wh(170, 40); .bgc(#d13639); .fs(14, 40px); .block; .tc; .abs; .lt(0, 20); border-radius: 0 8px 8px 0;}
    [battle-academia-main-symbol] {.t-s(.5); .m(0); .abs; .lt(-48, 30);}
    h3 { .fs(32, 38px); .ls(-1);}
    .date { .regular; .p(0, 12); .fs(12, 32px); .h(32); .br(20); .bgc(#757575); .max-w(208); .mt(12); .mb(16); }
    .msg {.fs(13, 18px); word-break: keep-all; }
    .count-box {.w(295); .mh-c; .p(20, 7); .-box; .bgc(#24262c); .br(4); .mt(20); .mb(40); .tc;
      [count-view] {.tc;
        &:nth-of-type(1) {.mr(16);}
      }
    }
    [section-title] {.mb(12);}
    .mov {.wh(290, 163); .bgc(#24262c); .br(4); .mb(40); .mh-c; }
    .flags {.w(295); .p(24, 8); .-box; .bgc(#24262c); .ib-list; .tc; .mh-c;
      li {.wh(80, 106); .tc; .ml(16); .bgc(#363840); .br(3); .c(rgba(255, 255, 255, .8));
        &:nth-of-type(1) {.ml(0);}
        [image-asset] {.wh(32, 40); .br(4); .mt(14);
          & + p {.fs(12, 14); .vam; .c(rgba(255, 255, 255, .8)); .mt(4); .p(0, 10); .tc;}
        }
      }
      .rank1 { .-t(#e3cb86, 6); }
      .rank2 { .-t(#a1bdca, 6); }
      .rank3 { .-t(#dd6e49, 6); }
    }
  }
  .banner {.p(92, 38); .bgc(#24262c); .abs; .w(100%); .-box; .lb(0, 0); .cover('@{bg}/coming.png'); .bg-c;
    h3 {.fs(32); .font-russo;}
    .sub-title {.fs(16); .mb(8); .font-russo;}
    .msg {.fs(14); .o(.6); .mt(16); .ls(.2);}
  }
  @media (@tl-up) {
    .p(120, 0, 80);
    .go-btn { .fs(24); .w(348); .mb(90); }
    [section-title] {
      h2 {.fs(24);
        span {.fs(16);}
      }
    }
    > h2 { .fs(48, 70px);
      > p { .pt(24); .mt(28);}
    }
    .holder-title { .wh(908, 66); .fs(30, 66); }
    .final-holder { .wh(908, 620); .-box; .mh-c; .rel;
      > .inside {.p(134, 32, 40, 250); .wh(100%);}
      > img {.t-x(0); .lt(-150, -70);}
      label {.t(36); .wh(192, 48); .lh(48);}
      [battle-academia-main-symbol] {.t-s(.7); .m(0); .abs; .lt(-10, 145);}
      .msg {.fs(13, 18px); .w(280);}
      .count-box {.w(480); .p(23, 24); .abs; .lb(36, 40); z-index: 1; .m(0);
        [count-view] {
          &:nth-of-type(1) {.mr(40);}
        }
      }
      .mov-holder { .abs; .rt(32, 130); }
      .rank-holder { .abs; .rb(32, 40);}
    }
    .banner {.p(100, 83);
      .sub-title {.fs(21); .mb(0);}
      h3 {.fs(52);}
      .msg {.fs(17); .mt(18);}
    }
  }
  @media (@ds-up) {
    .p(120, 0);
    .go-btn { .mb(70); }
    .holder-title { .w(1280); }
    .final-holder {.w(1280);
      > .inside {.pl(395); }
      [battle-academia-main-symbol] {.t-s(1.05); .m(0); .abs; .lt(70, 140);}
      .count-box {.w(692); .tc;}
      .mov-holder { .t(50);
        .mov {.wh(490, 275);}
      }
      .rank-holder {
        .flags {.w(490);}
      }
    }
    .banner { .p(80, 0, 70);
      h3 {.fs(72);}
      .msg {.mt(8);}
    }
  }
}
</style>
