<template>
  <PSection battle-academia-league-progress v-prx:intro="{ r: [3, 6], ty: [100, 0], o: [0, 1] }">
    <article class="progress" :class="`step-${progress}`" v-if="progress >=0">
      <p class="msg">평일 2회, 주말 3회, 3주간 진행</p>
      <!--      <p class="msg"  v-prx:intro="{r:[8,10], ty:[30,0], o:[0,1]}">평일 3번, 주말2번, 3주간 진행</p>-->
      <ul>
        <li>
          <p class="date">6월 21일 ~ 6월 27일</p>
          <h5>1주차 리그</h5>
          <em class="circle on" />
          <article class="schedule">
            <div class="time-holder">
              <h6 class="name">대회 일정</h6>
              <span>평일 <em class="status-tag time">19시</em><em class="status-tag time">21시</em></span>
              <span
              >주말 <em class="status-tag time">17시</em><em class="status-tag time">19시</em
              ><em class="status-tag time">21시</em></span
              >
            </div>
            <div class="time-holder">
              <h6 class="name">대회 접수</h6>
              <span>대회 시작 2일 전<em class="status-tag time last">18시</em>마감</span>
            </div>
          </article>
        </li>
        <li>
          <p class="date">6월 28일 ~ 7월 4일</p>
          <h5>2주차 리그</h5>
          <em class="circle" />
        </li>
        <li>
          <p class="date">7월 5일 ~ 7월 11일</p>
          <h5>3주차 리그</h5>
          <em class="circle" />
        </li>
        <li>
          <p class="date">7월 17일</p>
          <h5>파이널 토너먼트</h5>
          <em class="circle" />
          <article class="final">
            <p>누적 포인트 상위 8개팀 팀배틀 진행</p>
          </article>
        </li>
      </ul>
      <p class="line"><em class="def" /><em class="bar" /></p>
    </article>
  </PSection>
</template>

<script>
export default {
  name: 'BattleAcademiaLeagueProgress',
  props: {
    step: { type: Number, default: 0 },
  },
  components: {},
  data() {
    return {
      progress: -1,
      option: {
        touchRatio: 1,
        freeMode: true,
        slidesPerView: 'auto',
        breakpoints: {
          600: {
            touchRatio: 0,
          },
        },
      },
    };
  },
  mounted() {
    this.progress = this.$props.step;
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[battle-academia-league-progress] { .rel; .pt(20);
  .intro-up(4, {
    .progress .msg {.t-y(0); .o(1);}
  });
  .progress { .rel; .w(355); .mh-c;
    .msg {.wh(224, 42); .fs(16); .c(white); .-box; .p(6, 12); .abs; .lt(0, -55); .extra-bold; .contain('@{attach}/RedBubble.svg'); .o(0); .t-y(30); transition: .3s; .ls(-1); .font-g;}
    > ul { .ib-list; .rel; .pt(12); .pb(20); .wh(100%); .h; .bgc(#24262c); .br(8);
      > li {.w(33.333%); .rel; .hide;
        .date {.c(#394145); .fs(12, 24px); .h(24); .tc; .p(0, 8); .bgc(#ebf5f7); .br(4); .ib; .hide;}
        h5 {.fs(18); .mt(12); .hide;}
        .circle {.wh(40, 40); .br(20); .bgc(red); .block; .mh-c; .abs; .lt(50%, 80); .t-xc; z-index: 3; .hide;}
        .schedule {.w(335); .h; .bgc(#3d3f45); .br(8); .fs(14); .tl; .p(16, 14); .-box;.c(rgba(255, 255, 255, .8));
          .time-holder { .mt(20);
            &:nth-of-type(1) {.mt(0);}
            h6 {.fs(14); .bold; .mb(6); .font-spoqa;}
            span {.fs(14); .mr(15); .ib; .vam;
              em {
                &:nth-of-type(1) {.ml(6);}
                &.last {.mr(6);}
              }

              &:last-child {
                .mr(0);
              }
            }
          }
        }
        .final {.wh(335, 40); .bgc(#3d3f45); .br(8); .fs(14, 40px); .tc; .-box; .block;
          p {.c(rgba(255, 255, 255, .8));}
        }
        &:nth-of-type(1), &:nth-of-type(4) {.block; .ml(10);}
        &:nth-of-type(4) {.mt(10);}
      }
    }
    .line { .hide;}
  }
  @media (@tp-up) {
    .progress { .w(564);
      > ul { .p(20); .h(316);
        > li {.wh(25%, 100%); .ib !important; .tc; .m(0) !important; .rel;
          h5 {.block; .ls(-1);}
          .date {.block; .ib;}
          .circle {.block; .t(70); .wh(40); .bgc(#1a1d21); .-a(#363840, 4px);
            &:after {.cnt; .contain('@{icon}/step.svg'); .wh(24); .lt(50%, 50%); .t-xyc; .abs;}
          }
          .schedule {.w(373); .abs; .lb(0, 0);}
          .final {.wh(135, 145); .p(42, 33); .fs(14, 20px); .abs; .lb(0, 0);}
          &:after {.cnt; .abs; .wh(21, 11); .contain('@{icon}/Polygon-tri.svg'); .lb(50%, 144); .t-xc;}
          &:nth-of-type(1) .circle {.-a(#d13639, 4px);
            &:after {.contain('@{icon}/red-check.svg'); .wh(20);}
          }
        }
      }
      .line {.block; .abs; .lt(50%, 107); .t-xc; width: calc(100% - 40px); .h(4); z-index: 0;
        em {.block; .abs; .wh(100%);
          &.def {.bgc(#363840);}
          &.bar {.w(80); .bgc(#d13639);}
        }
      }
      &.step-1 {
        > ul {
          > li {
            &:nth-of-type(2) .circle {.-a(#d13639, 4px);
              &:after {.contain('@{icon}/red-check.svg'); .wh(20);}
            }
          }
        }
        .line em.bar {.w(180);}
      }
      &.step-2 {
        > ul {
          > li {
            &:nth-of-type(2) .circle, &:nth-of-type(3) .circle {.-a(#d13639, 4px);
              &:after {.contain('@{icon}/red-check.svg'); .wh(20);}
            }
          }
        }
        .line em.bar {.w(320);}
      }
      &.step-3 {
        > ul {
          > li {
            &:nth-of-type(2) .circle, &:nth-of-type(3) .circle, &:nth-of-type(4) .circle {.-a(#d13639, 4px);
              &:after {.contain('@{icon}/red-check.svg'); .wh(20);}
            }
          }
        }
        .line em.bar {.w(100%);}
      }
    }
  }

  @media (@ds-up) {
    .p(0);
  }
}
</style>
