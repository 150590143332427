<template>
  <h1 battle-academia-main-symbol>
    <span class="wrap">
      <template v-if="!progress">
        <em v-prx:intro="{r:[5,6.5,7], s:[0,2,1], o:[0,1]}" /> <em v-prx:intro="{r:[7.2,7.8], tx:[-50,0], o:[0,1]}" /> <em v-prx:intro="{r:[7.2,7.8], tx:[50,0], o:[0,1]}" /> <em v-prx:intro="{r:[7.8,8.6], ty:[10,0], o:[0,1]}" />
      </template>
      <template v-else>
        <em v-prx:progress="{r:[0,.5,1], s:[0,2,1], o:[0,1]}" /> <em v-prx:progress="{r:[1.2,1.8], tx:[-50,0], o:[0,1]}" /> <em v-prx:progress="{r:[1.2,1.8], tx:[50,0], o:[0,1]}" /> <em v-prx:progress="{r:[1.8,2.6], ty:[10,0], o:[0,1]}" />
      </template>
    </span>
  </h1>
</template>

<script>
export default {
  name: 'BattleAcademiaMainSymbol',
  props: {
    progress: { type: Boolean, default: true },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[battle-academia-main-symbol] { .rel; .wh(276, 210); .mh-c;
  .wrap {.block; .abs; .wh(100%);
    > em { .block; .abs;
      &:nth-of-type(1) {.wh(84, 78); .contain('@{logo}/BattleAcademia-01.svg'); .lt(50%, 0); .ml(-42);}
      &:nth-of-type(2) {.wh(98, 80); .contain('@{logo}/BattleAcademia-02.svg'); .lt(35, 35);}
      &:nth-of-type(3) {.wh(98, 80); .contain('@{logo}/BattleAcademia-03.svg'); .rt(35, 35); }
      &:nth-of-type(4) {.wh(276, 87); .contain('@{logo}/BattleAcademia-04.svg'); .lb(50%, 0); .ml(-138);}
    }
  }
}
</style>
