<template>
  <ul battle-academia-detail>
    <li>
      <BattleAcademiaMainSymbol :progress="false" />
      <article v-prx:intro="{ r: [2, 6], ty: [50, 0], o: [0, 1] }">
        <p class="copy">최고 대학에게<br />주어지는 점령 깃발-</p>
        <p class="date">
          <span>기간</span>
          6월 21일(월) ~ 7월 11일(일)
        </p>
        <p class="final date">
          <span>파이널 토너먼트</span>
          7월 17일(토)
        </p>
      </article>
      <router-link to="/b/landing/lol/Aleague" class="btn btn-more" v-prx:intro="{ r: [6, 8], o: [0, 1] }"
      >대회 안내 바로가기
      </router-link
      >
    </li>
    <li>
      <BattleAcademiaLeagueProgress :step="step" />
    </li>
  </ul>
</template>

<script>
import BattleAcademiaMainSymbol from '@/views/components/gameHome/lol/BattleAcademiaMainSymbol.vue';
import BattleAcademiaLeagueProgress from '@/views/components/gameHome/lol/BattleAcademiaLeagueProgress.vue';

export default {
  name: 'BattleAcademiaDetail',
  props: {
    step: { type: Number, default: 0 },
  },
  components: { BattleAcademiaLeagueProgress, BattleAcademiaMainSymbol },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[battle-academia-detail] { .mt(61); .rel; z-index: 2;
  > li {
    &:nth-of-type(1) { .ib-list; .w(335); .mh-c; .pb(40); .rel; .mb(70); .pointer;
      [battle-academia-main-symbol] {.t-s(.724); .ml(30);}
      article { .mt(0); .block; .tc;
        .copy {.fs(24, 28); .bold; .block; .tc; .mb(22); .font-g; .bold; .ls(-1);}
        .date {.bgc(#feffc5); .c(#191919); .fs(14); .p(7, 20, 7); .br(30); .mh-c; .mb(12); .tc; width: fit-content; .mh-c;
          > span:after {.cnt; .ib; .wh(1, 10); .m(0, 10); .bgc(#191919);}
          &.final {.bgc(#d13639); .c(white); width: fit-content;
            > span {
              &::after {.bgc(white);}
            }
          }
        }
      }
      .btn-more { .abs; .lb(50%, 0); .p(4, 16); .fs(14, 32px); .vam; .t-xc; .c(rgba(255, 255, 255, 0.8)); .bgc(#1e54b7); .br(8); .block; .tc;}
    }
  }
  @media (@tl-up) {
    .mt(58);
    > li {
      &:nth-of-type(1) { .w(610); .pb(40); .mb(90);
        [battle-academia-main-symbol] {.t-s(1); .ml(6); .mr(40); .ib;}
        article { .mt(70); .ib;
          .copy {.fs(32, 38px); .tl; .extra-bold; .mb(32); .ls(-2);}
          .date {.ib; .fs(14); .m(0); .mt(12); .block; .tl;}
        }
        .btn-more { .t-x(0); .lb(80, 0);}
      }
    }
  }
  @media (@ds-up) {
    .w(100%); .ib-list; .clear; .mt(90);
    > li {
      &:nth-of-type(1) { .w(610);.pb(20);
        article {.mt(75);}
        h1 {.wh(277, 214);}
      }
      &:nth-of-type(2) { .fr; }
    }
  }
}
</style>
